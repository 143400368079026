class Autocomplete {
    constructor() {
        // Create dynamic Address fields on the front-end
        window.autocomplete = window.autocomplete || {
            // Activate the Google Places Autocomplete on a specified input
            activateInput: function (id) {
                // Element ID for the location submit
                const locationSubmitEl = document.getElementById('location-submit');

                // Specify which input to use for autocompletion
                const $input = document.getElementById(id);

                // If input is missing, show warning and bail
                if (!$input) {
                    console.warn(`Unable to initialize Autocomplete, cannot find "${id}" input.`);
                    return;
                }

                // When typing a new query
                $input.addEventListener('change', e => {
                    e.preventDefault();

                    // Clear coordinates
                    document.getElementById('lat').value = '';
                    document.getElementById('lng').value = '';

                    // Disable submit button
                    locationSubmitEl.disabled = true;
                });

                const queryInputOptions = {
                    componentRestrictions: { country: ["au", "nz"] },
                    fields: [
                        'address_components',
                        'formatted_address',
                        'geometry.location',
                        'name',
                        'place_id'
                    ],
                    strictBounds: false,
                    types: ["(regions)"],
                };
                // Create an Autocomplete object
                const queryInput = new google.maps.places.Autocomplete($input, queryInputOptions);


                // Check if query input has content
                if ($input.value != null) {
                    // Enable submit button
                    locationSubmitEl.disabled = false;
                }

                // Listen for autocomplete trigger
                queryInput.addListener('place_changed', e => {
                    // e.preventDefault();

                    // Get newly selected place
                    let place = queryInput.getPlace();

                    // If a valid geometry was found
                    if (place.geometry ?? false) {
                        // Get new address info
                        let coords = place.geometry.location;
                        // Get coordinates
                        let lat = parseFloat(coords.lat().toFixed(7)) || null;
                        let lng = parseFloat(coords.lng().toFixed(7)) || null;
                        // Set coordinates
                        document.getElementById('lat').value = lat;
                        document.getElementById('lng').value = lng;

                        // Enable submit button
                        locationSubmitEl.disabled = false;
                    } else {
                        // Emit warning
                        console.warn('Unable to get place geometry');
                    }

                    // Submit the form
                    //window.submitForm();
                });

                // Prevent address selection from attempting to submit the form
                $input.addEventListener('keydown', (e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        // Stop enter key submitting the form
                        e.preventDefault();
                    }
                });

            }
        };
    }
    activateInput(el) {
        // Activate the Autocomplete input
        window.autocomplete.activateInput(el);
    }
    activateSalonLocator() {
        const elId = 'ss_pac_input';

        if (document.getElementById(elId)) {
            this.activateInput(elId);
        }
    }
}

export default new Autocomplete();
